import * as React from 'react';
import Layout from '../components/layout';
import Form from '../components/common/form';
import SEO from '../components/seo';

const text = {
  title: 'Request a Demo',
  description: 'Use the form below to get in touch with the LogRocket team for a personalized demo.',
  buttonValue: 'Request a Demo'
}

const IndexPage = () => (
  <Layout>
    <SEO title='LogRocket - Request a demo' description={['LogRocket helps you understand problems affecting your users, so that you can get back to building great software.']} />
    <Form text={text} isOnprem={true} isMessage={false} />
  </Layout>
);

export default IndexPage;
